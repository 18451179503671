<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        :src="require('@/assets/images/logo/logo.svg')"
        alt="Surfe logo"
        style="max-width:150px"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100">
        <div v-if="failure">
          <h2 class="mb-1">
            Connection failed
          </h2>
          <b-button
            variant="primary"
            to="/enrichment-hub/connect"
            class="mb-1 btn-sm-block"
          >
            Go back
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BLink, BButton, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      failure: false,
      loading: false,
    }
  },
  async mounted() {
    const appLoading = document.getElementById('loading-bg')

    // Show spinner
    if (appLoading) appLoading.style.display = 'block'

    try {
      const { code } = this.$route.query
      const url = `${process.env.VUE_APP_EH_API_URL}/sso/callback?code=${code}`
      const { data } = await axios.post(url, {})

      // Store access token
      localStorage.setItem('token', data.token)
      localStorage.setItem('eh_user', data.user.email)

      this.$router.push('/enrichment-hub/home')
    } catch (error) {
      this.failure = true
      console.error(error)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'An error occurred',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    }

    // Hide spinner
    if (appLoading) appLoading.style.display = 'none'
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
